import React from 'react'

import LayoutSub from "../../components/layoutSub";
import SEO from "../../components/seo";
import ExLink from "../../components/exLink";

export default function Privacy( {location, pageContext} ) {

  const { breadcrumb: { crumbs } } = pageContext;
  const {pathname} = location;

  const pagetitle = "Privacy Policy";
  const description = "The privacy of your data is a big deal to us. Here’s the rundown of what we collect and why, when we access your information, and your rights.";
   
    return (


            <LayoutSub crumbs={crumbs} pathname={pathname} lang='en'>
      <SEO
        pageurl={pathname}
        pagetitle={pagetitle}
        description={description}
        lang="en"
      />

        <h1 className="heading-b">Privacy Policy</h1>

<strong>Last updated: April 11, 2022</strong>
<p>
The privacy of your data — and it is your data, not ours! — is a big deal to us. In this policy, we lay out: what data we collect and why; how your data is handled; and your rights to your data. We promise we never sell your data: never have, never will.</p>

<p>
This policy applies to all products/services provided and maintained by Penguindou.</p>

<h2>What we collect and why</h2>

<p>
Our guiding principle is to collect only what we need. Here&apos;s what that means in practice:</p>

<h3>Identity & access</h3>
<p>
When you contact us, we typically ask for identifying information such as your name, email address, and phone number. That’s just so we can send you updates or other essential information. We&apos;ll never sell your personal info to third parties, and we won&apos;t use your name or company in marketing statements without your permission either.</p>



<h3>Website interactions</h3>

<p>
When you browse our marketing pages or applications, your browser automatically shares certain information such as which operating system and browser version you are using. We track that information, along with the pages you are visiting, page load timing, and which website referred you for statistical purposes like conversion rates and to test new designs. We sometimes track specific link clicks to help inform some design decisions.</p>

<h3>Cookies and Do Not Track</h3>
<p>
We do use persistent first-party cookies to store certain preferences. A cookie is a piece of text stored by your browser to help it remember your login information, site preferences, and more. You can adjust cookie retention settings in your own browser. To learn more about cookies, including how to view which cookies have been set and how to manage and delete them, please visit:&nbsp;<ExLink href="https://www.allaboutcookies.org">www.allaboutcookies.org</ExLink></p>
<p>
At this time, our sites and applications do not respond to Do Not Track beacons sent by browser plugins.</p>

<h3>Voluntary correspondence</h3>
<p>
When you write Penguindou with a question or to ask for help, we keep that correspondence, including the email address, so that we have a history of past correspondences to reference if you reach out in the future.</p>
<p>
We also store any information you volunteer like surveys. Sometimes when we do customer interviews, we may ask for your permission to record the conversation for future reference or use. We only do so if you give your express consent.</p>

<h2>When we access or share your information</h2>
<p>
Sensitive information will be used by us only:</p>
    <ul>
    <li>For the primary purpose for which it was obtained</li>


<li>For a secondary purpose that is directly related to the primary purpose</li>
<li>With your consent; or where required or authorized by law.</li>    </ul>

<h2>Your rights with respect to your information</h2>
<p>
You have the right to know what personal information is collected, used, shared or sold. We outline both the categories and specific bits of data we collect, as well as how they are used, in this privacy policy.</p>
<p>You have the right to request correction of your personal information.</p>

<h2>Security of Personal Information</h2>
<p>
Your Personal Information is stored in a manner that reasonably protects it from misuse and loss and from unauthorized access, modification or disclosure.</p>
<p>
When your Personal Information is no longer needed for the purpose for which it was obtained, we will take reasonable steps to destroy or permanently de-identify your Personal Information. However, most of the Personal Information is or will be stored in client files which will be kept by us for a minimum of 10 years.</p>

<h2>Google Adsense and the DoubleClick DART Cookie</h2>

<p>
Google, as a third party advertisement vendor, may use cookies to serve ads on this https://penguindo.net/ website. The use of DART cookies by Google enables them to serve adverts to visitors that are based on their visits to this website as well as other websites on the internet.</p>

<p>To opt out of the DART cookies you may visit <ExLink href="http://www.google.com/privacy_ads.html">the Google ad and content network privacy policy</ExLink> Tracking of users through the DART cookie mechanisms are subject to Google&#x27;s own privacy policies.</p>

<p>Other Third Party ad servers or ad networks may also use cookies to track users activities on this website to measure advertisement effectiveness and other reasons that will be provided in their own privacy policies, our website has no access or control over these cookies that may be used by third party advertisers.</p>

<h2>Policy Updates</h2>
<p>
This Policy may change from time to time and is available on our website.</p>

<h2>Privacy Policy Complaints and Enquiries</h2>

<p>
If you have any queries or complaints about our Privacy Policy please contact us at:

Address: 2-26-2, Nishiogi-minami, Suginami-ku, Tokyo, 167-0053
Tel: 070-4200-8493
</p>




      </LayoutSub>

       
         
    )
}